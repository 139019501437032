import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Individual, IndividualApiService, IndividualService } from '../../../shared/services/individuals/individual.service';
import lodash from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CommonServiceService } from '../../../shared/services/common-service.service';
import moment from 'moment';
import { LoaderService } from '../../../shared/services/loader/loader.service';
@Component({
  selector: 'app-add-individual',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './add-individual.component.html',
  styleUrl: './add-individual.component.scss',
})
export class AddIndividualComponent {
  @Output() closeModel = new EventEmitter();
  @Input() nonInviteIndList: Individual[] = [];
  @Input() orgID: string = null;
  selectedInd: Individual | any;
  individualForm: FormGroup;
  isShowDetails: boolean = false;
  activeIndex: number = 0;

  constructor(
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private indService: IndividualService,
    private individualApiService: IndividualApiService,
    private commonFun: CommonServiceService,
    private loaderService: LoaderService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.individualForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      studentID: ['', Validators.required],
      indID_Client: ['', Validators.required],
      email: ['', [Validators.email]],
      mobilePhone: ['', Validators.required]
    });
  }

  showSlot(selectedInd) {
    this.isShowDetails = true;
    if (selectedInd) {
      this.individualForm.patchValue({
        firstName: selectedInd.firstName,
        lastName: selectedInd.lastName,
        studentID: selectedInd.studentID || "",
        indID_Client: selectedInd.indID_Client || "",
        email: selectedInd.email || "",
        mobilePhone: selectedInd.mobilePhone || "",
      });
      this.copyObj = lodash.cloneDeep(this.individualForm.value);
    }
  }

  copyObj: string = '';
  async onSubmit(isCreateNewInd: boolean = false) {
    this.loaderService.show();
    try {
      if (this.individualForm.valid) {
        if (isCreateNewInd) {
          let pushKey = this.commonFun.createIndId();
          const formValues = this.individualForm.value || '';
          let indObj = {
            _key: pushKey,
            lastUpdatedBy: 'CSV Import',
            timestampCreated: moment().valueOf(),
            firstName: formValues.firstName || '',
            lastName: formValues.lastName,
            role: 'Student',
            email: formValues.email || '',
            mobilePhone: formValues.mobilePhone
              ? formValues.mobilePhone.includes('+')
                ? formValues.mobilePhone
                : '+1' + formValues.mobilePhone
              : '',
            studentID: formValues.studentID || '',
            indID_Client: formValues.indID_Client || '',
            genNewPW: true,
          };
          await this.individualApiService
            .addIndividualInDb(this.orgID, pushKey, indObj)
            .then(() => {
              this.selectedInd = { ...indObj, DBkey: pushKey };
            });
        } else {
          if (!this.checkData()) {
            await this.indService.updateIndividual(
              this.selectedInd.DBkey,
              {
                email: this.individualForm.value.email,
                mobilePhone: this.individualForm.value.mobilePhone,
              },
              '',
              this.orgID
            );
          }
          this.selectedInd.email = this.individualForm.value.email;
          this.selectedInd.mobilePhone = this.individualForm.value.mobilePhone;
        }
        //emit close event and pass selectedInd's data.
        this.closeModel.emit(this.selectedInd);
      } else {
        this.individualForm.markAllAsTouched();
      }
      this.loaderService.hide();
    } catch (error) {
      this.loaderService.hide();
      this.messageService.add({
        severity: 'error',
        summary: 'Error',
        detail:
          this.commonFun.prepareErrorMessage(error) ||
          'Something went wrong, Please try again later.',
        life: 4000,
      });
      console.error(error);
    } finally {
      this.loaderService.hide();
    }
  }

  checkData() {
    return JSON.stringify(this.copyObj) == JSON.stringify(this.individualForm.value);
  }

  closeDialog(isFromSave: boolean = false) { 
    if (isFromSave || this.checkData()) {
      this.closeModel.emit(null);
      this.resetForm();
    } else {
      this.openConfirmationDialog(
        'Attention',
        'You have unsaved changes. What do you want to do?',
        'Forget changes',
        'Continue editing',
        false,
        true,
        true
      ).then((res: any) => {
        if (res) {
          this.closeModel.emit(null);
          this.resetForm();
        }
      })
    }
  }

  resetForm() {
    this.individualForm.reset();
    this.selectedInd = null;
  }

  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none'
      });
    });
  }

  clearForm() {
    this.individualForm.reset();
  }
}
