<div class="h-full d-flex flex-col booking-info">
    <div class="d-flex gap-4 flex-grow">
        <div class="configuration-section pt-3 mt-13px" *ngIf="!isEmpty(currentSlotData)">
            <h3 class="m-0 mb--2">Currently Selected Slot</h3>
            <table class="configuration-table">
                <thead>
                    <tr>
                        <th scope="col">Setting</th>
                        <th scope="col">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Subject</td>
                        <td>
                            <div class="d-flex gap-2">
                                <span class="p-float-label cus-float-label input-sm cus-float-input">
                                    <p-dropdown (onChange)="getSlotByIndId(currentSlotData.indID)"
                                        class="w-full cmn-dropdown filter-input" [(ngModel)]="currentSlotData.indID"
                                        optionLabel="indName" optionValue="indId" [options]="subjects" [filter]="true"
                                        filterBy="indName" placeholder="Subject"></p-dropdown>
                                </span>
                                <p-button (onClick)="showAddEditIndModelDialog()"
                                    class="rounded-full cmn-btn-primary btn-sm h-30px"><img
                                        src="../../../../assets/svg/more_horiz.svg" alt=""></p-button>
                            </div>
                            <div *ngIf="!currentSlotData.indID && isShowErrMsg" class="invalid-feedback text-start">
                                Subject is required.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Appointment</td>
                        <td>
                            <span class="p-float-label cus-float-label input-sm cus-float-input">
                                <p-dropdown class="w-full cmn-dropdown" optionLabel="key" optionValue="value"
                                    [(ngModel)]="currentSlotData.status_appointment" [options]="appointmentStatus"
                                    placeholder="Appointment"></p-dropdown>
                            </span>
                            <div *ngIf="!currentSlotData.status_appointment && isShowErrMsg"
                                class="invalid-feedback text-start">
                                Appointment is required.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Sitting</td>
                        <td>
                            <span class="p-float-label cus-float-label input-sm cus-float-input">
                                <p-dropdown class="w-full cmn-dropdown" optionLabel="label" optionValue="value"
                                    [(ngModel)]="currentSlotData.status_sit" [options]="sittings"
                                    placeholder="Sitting"></p-dropdown>
                            </span>
                            <div *ngIf="!currentSlotData.status_sit && isShowErrMsg"
                                class="invalid-feedback text-start">
                                Sitting is required.
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Start Time</td>
                        <td>
                            <span class="p-float-label cus-float-label input-sm cus-float-input">
                                {{currentSlotData?.startTimeHour || '-'}}
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td>End Time</td>
                        <td>
                            <span class="p-float-label cus-float-label input-sm cus-float-input">
                                {{currentSlotData?.endTimeHour || '-'}}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
            <ng-container>
                <ng-container>
                    <div class="w-full">
                        <h5 class="m-0 date-time-heading">Please select preferred datetime (optional)</h5>
                        <h5 class="mb-2 date-time-heading">Select only if you want to set new datetime</h5>
                        <span class="p-float-label cus-float-label">
                            <p-dropdown class="w-full cmn-dropdown" [options]="slotPreferredDate"
                                [(ngModel)]="selectedPreferredDate" optionLabel="date" optionValue="date"
                                placeholder="Select preferred date" (onChange)="preferredValueChange()"></p-dropdown>
                            <label for="float-label">Preferred new date</label>
                        </span>
                        <div class="invalid-feedback text-start" *ngIf="!selectedPreferredDate && isShowErrorMassage">
                            Preferred date
                            is
                            required</div>
                    </div>
                    <div class="w-full">
                        <span class="p-float-label cus-float-label">
                            <p-dropdown class="w-full cmn-dropdown" [(ngModel)]="selectedTimeSlot"
                                [options]="slotPreferredTime" optionLabel="date" optionValue="slotId"
                                placeholder="Select preferred time"></p-dropdown>
                            <label for="float-label">Preferred new time</label>
                        </span>
                        <div class="invalid-feedback text-start" *ngIf="!selectedPreferredDate && isShowErrorMassage">
                            Preferred time
                            is
                            required</div>
                    </div>
                </ng-container>
                <div class="d-flex gap-2">
                    <!-- <p-button label="Cancel" class="rounded-full cmn-btn-primary w-full"
                                (onClick)="onCancelSlotBooking()"></p-button> -->
                    <p-button (onClick)="onUpdateSlot()" label="Update"
                        class="rounded-full cmn-btn-primary w-full"></p-button>
                    <p-button *ngIf="currentSlotData?.indID" (onClick)="removeIndFromSlot()" class="rounded-full cmn-btn-secondary w-full">
                        <img src="../../../../assets/svg/trash.svg" class="w-h-21px" alt="">
                        Remove
                    </p-button>
                </div>
            </ng-container>
        </div>

        <div class="w-full d-flex flex-col">
            <div class="btn-group mb-3">
                <p-button class="cmn-btn-primary " mwlCalendarPreviousView [view]="'day'" [(viewDate)]="viewDate"
                    [disabled]="viewDate <= eventStartDate ? true : false">
                    Previous
                </p-button>
                <div>
                    <h3 class="m-0">{{ viewDate | calendarDate:('dayViewTitle') }}</h3>
                </div>
                <p-button class="cmn-btn-primary" [view]="'day'" [(viewDate)]="viewDate" mwlCalendarNextView
                    [disabled]="viewDate >= eventEndDate ? true : false">
                    Next
                </p-button>
                <p-button class="cmn-btn-primary" mwlCalendarToday [(viewDate)]="viewDate">
                    Today
                </p-button>
                <div class="min-w-250px ms-auto">
                    <span class="p-float-label cus-float-label input-sm search-input cus-float-input">
                        <p-dropdown (onChange)="showSlot(searchSlot)" class="w-full cmn-dropdown filter-input"
                            [(ngModel)]="searchSlot" optionLabel="indData.indName" [options]="searchSlotList"
                            [filter]="true" [showClear]="true"
                            [filterFields]="['indData.firstName', 'indData.lastName', 'indData.studentID', 'indData.indID_Client', 'indData.indName']"
                            placeholder="Search Here">
                        </p-dropdown>
                        <label>Search</label>
                    </span>
                </div>
                @if (!isFromPublicPage) {
                <div class="btn-group">
                    <!-- <p-button class="cmn-btn-primary rounded-full outlined back-btn" label="Back" icon="pi pi-angle-left" (click)="goBack()"></p-button> -->
                    <p-button *ngIf="slots && slots.length > 0" class="cmn-btn-primary rounded-full back-btn ms-auto"
                        label="Export To Excel" (click)="exportData()"></p-button>
                </div>
                }
                @if (isFromPublicPage) {
                <button class="toggle-button" [class.active]="isFullscreenActive" id="toggle-btn"
                    (click)="toggleFullscreen()"
                    [pTooltip]="isFullscreenActive ? 'Exit Fullscreen' : 'Enter Fullscreen'" tooltipPosition="left"
                    tooltipStyleClass="small-tooltip" fitContent="true">
                    <div class="row _1">
                        <div class="box _1"></div>
                        <div class="box _2"></div>
                    </div>
                    <div class="row _2">
                        <div class="box _3"></div>
                        <div class="box _4"></div>
                    </div>
                </button>
                }
            </div>

            <div class="booking-info-calender flex-grow" [ngClass]="{'w-full': isEmpty(currentSlotData)}">
                <app-schedule-configuration-calendar [viewDate]="viewDate" [events]="slots" [users]="tracks"
                    (eventClicked)="onSlotClick($event)" (eventTimesChanged)="eventTimesChanged($event)"
                    (userChanged)="userChanged($event)">
                </app-schedule-configuration-calendar>
            </div>
        </div>
    </div>

</div>

<p-dialog class="cus-dialog edit-district-dialog" [header]="'Add subject to event'" [(visible)]="showAddEditIndModel"
    [modal]="true"
    [style]="{'max-width.px': '800', 'width': '100%', 'box-shadow': 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px', 'border-radius': '16px'}"
    [draggable]="false" [resizable]="false">
    <app-add-individual (closeModel)="closeAddEditIndDialog($event)" [nonInviteIndList]="nonInviteIndList"
        [orgID]="orgId"></app-add-individual>
</p-dialog>
<p-toast></p-toast>
<p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>